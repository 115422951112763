import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStar } from "@fortawesome/free-solid-svg-icons";

export enum navLinks {
  HOME = "HOME",
  ABOUT = "ABOUT",
  CAMPAIGN = "CAMPAIGN",
  CUSTOM = "CUSTOM",
  // COLLECTION = "COLLECTION",
  CONTACT = "CONTACT",
  // WISHLIST = "WISHLIST",
}
interface LinksProps {
  activeLink: navLinks;
  handleLinks: (link: navLinks) => void;
  count: number;
}
const Links = ({ activeLink, handleLinks, count }: LinksProps) => {
  const linkClassName = (name: navLinks) =>
    activeLink === name
      ? "bm-item nav-link-element active"
      : "bm-item nav-link-element";

  return (
    <>
      <Link
        to="/about"
        className={linkClassName(navLinks.ABOUT)}
        onClick={() => handleLinks(navLinks.ABOUT)}
      >
        {navLinks.ABOUT}
      </Link>
      <Link
        to="/campaign"
        className={linkClassName(navLinks.CAMPAIGN)}
        onClick={() => handleLinks(navLinks.CAMPAIGN)}
      >
        {navLinks.CAMPAIGN}
      </Link>
      {/* <Link
        to="/collection"
        className={linkClassName(navLinks.COLLECTION)}
        onClick={() => handleLinks(navLinks.COLLECTION)}
      >
        {navLinks.COLLECTION}
      </Link> */}
      <Link
        to="/contact"
        className={linkClassName(navLinks.CONTACT)}
        onClick={() => handleLinks(navLinks.CONTACT)}
      >
        {navLinks.CONTACT}
      </Link>
      {/* <Link
        to="/wishlist"
        className={linkClassName(navLinks.WISHLIST)}
        onClick={() => handleLinks(navLinks.WISHLIST)}
      >
        {Boolean(count) && `(${count})`}
        <FontAwesomeIcon icon={faStar} />
      </Link> */}
    </>
  );
};

export default Links;
