import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Nav/Header";
import Burger from "./Nav/Burger";
import HomePage from "./Containers/HomePage";
import About from "./Containers/About";
import Contact from "./Containers/Contact";
import Campaign from "./Containers/Campaign";
import React, { useState, useEffect } from "react";
import Cielo from "./Components/Campaign/Cielo";
import Collection from "./Containers/Collection";
import Wishlist from "./Containers/Wishlist";
import ThanksForOrder from "./Containers/ThanksForOrder";
import useLocalStorage from "./Utils/useLocalStorage";
import DoppelGanger from "./Components/Campaign/DoppelGanger";
import LostAngeles from "./Components/Campaign/LostAngeles";
import Katabasis from "Components/Campaign/Katabasis";

function App() {
  const [isHome, setIsHome] = useState(true);
  const [count, setCount] = useState(0);
  const [wishlist] = useLocalStorage("wishlist-doppelganger", []);
  useEffect(() => {
    const url = window.location.href.split("/");
    window.localStorage.removeItem("wishlist");
    if (Boolean(url[3]) && url[3][0] !== "?") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
    setCount(wishlist?.length ? wishlist.length : 0);
  }, [isHome, wishlist]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setWidth(window.screen.width);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <>
            <div className="AppContainer">
              <>
                {width < 850 ? (
                  <Burger isHome={isHome} setIsHome={setIsHome} count={count} />
                ) : (
                  <Header isHome={isHome} setIsHome={setIsHome} count={count} />
                )}

                <Route
                  path="/"
                  exact
                  render={() => <HomePage width={width} />}
                />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                {/* <Route
                  path="/collection"
                  render={() => <Collection setCount={setCount} />}
                /> */}
                {/* <Route
                  path="/wishlist"
                  render={() => <Wishlist setCount={setCount} />}
                /> */}
                <Route
                  path="/confirmation-request"
                  render={() => <ThanksForOrder setCount={setCount} />}
                />
                <Route path="/campaign/cielo" render={() => <Cielo />} />
                <Route
                  path="/campaign/doppelganger"
                  render={() => <DoppelGanger />}
                />
                <Route
                  path="/campaign/lostangeles"
                  render={() => <LostAngeles />}
                />
                <Route
                  path="/campaign/katabasis"
                  render={() => <Katabasis />}
                />
                <Route path="/campaign" exact render={() => <Campaign />} />
              </>
            </div>
          </>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
