import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Links, { navLinks } from "./Links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { HeaderProps } from "./Header";

const Burger = ({ isHome, setIsHome, count }: HeaderProps) => {
  const [activeLink, setActiveLink] = useState<navLinks>(navLinks.HOME);

  const linkClassName = (name: navLinks) =>
    activeLink === name ? "nav-link-element active" : "nav-link-element";

  useEffect(() => {
    if (isHome) {
      setActiveLink(navLinks.HOME);
    } else {
      const url = window.location.href.split("/");
      setActiveLink(url[3].toUpperCase() as navLinks);
    }
  }, [activeLink, isHome]);

  const handleLinks = (link: navLinks) => {
    if (link === navLinks.HOME) {
      setIsHome(true);
    } else {
      setActiveLink(link);
      setIsHome(false);
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleStateChange = (state: any) => {
    setIsMenuOpen(state.isOpen);
  };

  const closeMenu = (link: navLinks) => {
    handleLinks(link);
    setIsMenuOpen(false);
  };

  return (
    <>
      <Menu
        //@ts-ignore
        left
        slide
        customBurgerIcon={<FontAwesomeIcon className="fa" icon={faBars} />}
        disableAutoFocus
        isOpen={isMenuOpen}
        onStateChange={(state: any) => handleStateChange(state)}
      >
        <Link
          to="/"
          className={linkClassName(navLinks.HOME)}
          onClick={() => closeMenu(navLinks.HOME)}
        >
          {navLinks.HOME}
        </Link>

        <Links handleLinks={closeMenu} count={count} activeLink={activeLink} />
      </Menu>
      <div className="header-burger">
        <Link
          to="/"
          className={linkClassName(navLinks.HOME)}
          onClick={() => closeMenu(navLinks.HOME)}
          style={{ display: "flex" }}
        >
          <img
            aria-label="squillace 1923 logo"
            className="logo-burger"
            src="/squillace1923.jpg"
            alt="logo Squillace 1923"
          />
        </Link>
        {/* <Link
          to="/wishlist"
          className={`${linkClassName(navLinks.WISHLIST)} burger-star`}
          onClick={() => closeMenu(navLinks.WISHLIST)}
        >
          {Boolean(count) && `(${count})`}
          <FontAwesomeIcon icon={faStar} />
        </Link> */}
      </div>
    </>
  );
};

export default Burger;
