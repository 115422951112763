import React, { useEffect, useState } from "react";
import Slideshow from "../Components/Slideshow";
import Footer from "../Components/Footer";

import { Helmet } from "react-helmet";
import { schemaHome } from "../schema";

const HomePage = ({ width }: { width: number }) => {
  const [isSuperSlowNetwork, setIsSuperSlowNetwork] = useState<boolean>(false);

  useEffect(() => {
    const test = () => {
      let time_start: number | undefined;
      let end_time: number | undefined;

      // The size in bytes
      const downloadSize = 538000;
      const downloadImgSrc = new Image();

      downloadImgSrc.onload = function () {
        end_time = new Date().getTime();
        displaySpeed();
      };
      time_start = new Date().getTime();
      downloadImgSrc.src = "/lostangeles/LostAngeles0.jpg";

      const displaySpeed = () => {
        if (end_time && time_start) {
          const timeDuration = (end_time - time_start) / 1000;
          const loadedBits = downloadSize * 8;
          const bps = loadedBits / timeDuration;
          const speedInKbps = bps / 1024;
          const speedInMbps = speedInKbps / 1024;
          if (speedInMbps < 5) {
            setIsSuperSlowNetwork(true);
          }
        }
      };
    };

    test();
  }, []);

  return (
    <div className="content-home">
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(schemaHome)}
        </script>

        <meta charSet="utf-8" />
        <title>
          Squillace 1923 - Luxury leather gloves brand handmade in Italy
        </title>
        <meta
          name="description"
          content="Squillace 1923 is the Haute Couture brand by Omega srl. 
    Elegant leather gloves collections, made in Naples following the ancestral phases of production, entirely handmade."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1 style={{ display: "none", position: "absolute" }}>
        Squillace Gloves leather handmade
      </h1>
      <div className={"slideshow-box"}>
        {!isSuperSlowNetwork ? (
          <>
            <iframe
              className="video-home"
              src={
                width < 850
                  ? "https://www.youtube.com/embed/tJYqlNeQoaQ&ab?autoplay=1&playlist=tJYqlNeQoaQ&abloop=1&modestbranding=1&showinfo=0&mute=1&color=white"
                  : "https://www.youtube.com/embed/4xjPnS303kU?autoplay=1&playlist=4xjPnS303kU&loop=1&modestbranding=1&showinfo=0&mute=1&color=white"
              }
              width="100%"
              frameBorder="0"
              allow="autoplay"
              loading="lazy"
              allowFullScreen
              title="Katabasis"
            />
          </>
        ) : (
          <Slideshow />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
